
export const QuezoneRegionConfig = {
  IN: {
    label: 'India',
    value: 'IN',
    defaultCountryCode: "IN",
    domain: 'que.zone',
    domainUrl: 'https://que.zone',
    onboardingUrl: 'https://provider.que.zone',

    BASE_URL: "https://api.que.zone",
    AWS_CONFIG: {
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-south-1:68c42364-efa7-405d-978a-a6b0d0597552',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-south-1',
        identityPoolRegion: 'ap-south-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_gWBKGITKA',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: 'b86go3nlndo5td74but3uvaaq',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
      },
      // hosted ui configuration
      oauth: {
        domain: 'que-zone.auth.ap-south-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000,https://que.zone',
        redirectSignOut: 'http://localhost:3000,https://que.zone',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    },
    FB_APP_ID: '243175483037775',
    FB_API_VERSION: 'v3.3',
    PROVIDER: {
      GOOGLE: 'google',
      FACEBOOK: 'facebook',
      QUEZONE: 'quezone',
    },
    QZ_COGNITO_IDENTITY_KEY: 'qz.auth.cognito.identity',
    AUTH_METHOD: 'auth2',
    GOOGLE_ID: '628080743577-4jujtdfhclapkg13pgbb2smflaah0vem.apps.googleusercontent.com',
    GOOGLE_SECRET: 'vRfkxHyr3Wwxjm8ZAYl7HcGJ',
    GOOGLE_GEO_API_KEY: 'AIzaSyATIXiytQDXMl_QyQWWD4t5L7JNgh6Eq_c',
    GOOGLE_CAPTCHA_SITE_KEY: '6Lc29bsUAAAAALLD3huMZVHQA2IL9UxJBDjTT6kH',
    GOOGLE_CAPTCHA_SECRET: '6Lc29bsUAAAAAL6j1N7wPWsH9EXcvqSstSQZQXX0',
  },
  AU: {
    label: 'Australia',
    value: 'AU',
    defaultCountryCode: "AU",
    domain: 'custweb.quezone.com.au',
    domainUrl: 'https://custweb.quezone.com.au',
    onboardingUrl: 'https://onboarding.quezone.com.au',

    BASE_URL: "https://api.quezone.com.au",
    AWS_CONFIG: {
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-southeast-2:adab2657-684c-4222-a17a-9a82b6a5ee84',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-southeast-2',
        identityPoolRegion: 'ap-southeast-2',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-southeast-2_0sAegznUX',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3ov1blo2eji4acnqfcv88tcidn',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
      },
      oauth: {
        domain: 'que-zone.auth.ap-southease-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000,https://quezone.co.au',
        redirectSignOut: 'http://localhost:3000,https://quezone.co.au',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    FB_APP_ID: '243175483037775',
    FB_API_VERSION: 'v3.3',
    PROVIDER: {
      GOOGLE: 'google',
      FACEBOOK: 'facebook',
      QUEZONE: 'quezone',
    },
    QZ_COGNITO_IDENTITY_KEY: 'qz.auth.cognito.identity',
    AUTH_METHOD: 'auth2',
    GOOGLE_ID: '628080743577-4jujtdfhclapkg13pgbb2smflaah0vem.apps.googleusercontent.com',
    GOOGLE_SECRET: 'vRfkxHyr3Wwxjm8ZAYl7HcGJ',
    GOOGLE_GEO_API_KEY: 'AIzaSyATIXiytQDXMl_QyQWWD4t5L7JNgh6Eq_c',
    GOOGLE_CAPTCHA_SITE_KEY: '6Lc29bsUAAAAALLD3huMZVHQA2IL9UxJBDjTT6kH',
    GOOGLE_CAPTCHA_SECRET: '6Lc29bsUAAAAAL6j1N7wPWsH9EXcvqSstSQZQXX0',
  },
  AU_TEST: {
    label: 'Australia-Test',
    value: 'AU_TEST',
    defaultCountryCode: "AU",
    domain: 'testcustweb.quezone.com.au',
    domainUrl: 'https://testcustweb.quezone.com.au',
    onboardingUrl: 'https://testonboarding.quezone.com.au',

    BASE_URL: "https://apitesting.quezone.com.au",
    AWS_CONFIG: {
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-southeast-2:adab2657-684c-4222-a17a-9a82b6a5ee84',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-southeast-2',
        identityPoolRegion: 'ap-southeast-2',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-southeast-2_0sAegznUX',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3ov1blo2eji4acnqfcv88tcidn',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
      },
      oauth: {
        domain: 'que-zone.auth.ap-southease-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000,https://quezone.co.au',
        redirectSignOut: 'http://localhost:3000,https://quezone.co.au',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    FB_APP_ID: '243175483037775',
    FB_API_VERSION: 'v3.3',
    PROVIDER: {
      GOOGLE: 'google',
      FACEBOOK: 'facebook',
      QUEZONE: 'quezone',
    },
    QZ_COGNITO_IDENTITY_KEY: 'qz.auth.cognito.identity',
    AUTH_METHOD: 'auth2',
    GOOGLE_ID: '628080743577-4jujtdfhclapkg13pgbb2smflaah0vem.apps.googleusercontent.com',
    GOOGLE_SECRET: 'vRfkxHyr3Wwxjm8ZAYl7HcGJ',
    GOOGLE_GEO_API_KEY: 'AIzaSyATIXiytQDXMl_QyQWWD4t5L7JNgh6Eq_c',
    GOOGLE_CAPTCHA_SITE_KEY: '6Lc29bsUAAAAALLD3huMZVHQA2IL9UxJBDjTT6kH',
    GOOGLE_CAPTCHA_SECRET: '6Lc29bsUAAAAAL6j1N7wPWsH9EXcvqSstSQZQXX0',
  },
  IN_TEST: {
    label: 'India-TEST',
    value: 'IN_TEST',
    defaultCountryCode: "IN",
    domain: 'localhost',
    domainUrl: 'https://testing.que.zone',
    onboardingUrl: 'https://testing.provider.que.zone',

    BASE_URL: "https://apitesting.que.zone",
    AWS_CONFIG: {
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-south-1:baa80567-968e-4acb-8a13-93037b755fee',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-south-1',
        identityPoolRegion: 'ap-south-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_tqaoV6YUZ',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3q88su8c01eov2r9hlpcupj2rt',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
      },
      // hosted ui configuration
      oauth: {
        domain: 'que-zone.auth.ap-south-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000,https://que.zone',
        redirectSignOut: 'http://localhost:3000,https://que.zone',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    },
    FB_APP_ID: '243175483037775',
    FB_API_VERSION: 'v3.3',
    PROVIDER: {
      GOOGLE: 'google',
      FACEBOOK: 'facebook',
      QUEZONE: 'quezone',
    },
    QZ_COGNITO_IDENTITY_KEY: 'qz.auth.cognito.identity',
    AUTH_METHOD: 'auth2',
    GOOGLE_ID: '628080743577-4jujtdfhclapkg13pgbb2smflaah0vem.apps.googleusercontent.com',
    GOOGLE_SECRET: 'vRfkxHyr3Wwxjm8ZAYl7HcGJ',
    GOOGLE_GEO_API_KEY: 'AIzaSyATIXiytQDXMl_QyQWWD4t5L7JNgh6Eq_c',
    GOOGLE_CAPTCHA_SITE_KEY: '6Lc29bsUAAAAALLD3huMZVHQA2IL9UxJBDjTT6kH',
    GOOGLE_CAPTCHA_SECRET: '6Lc29bsUAAAAAL6j1N7wPWsH9EXcvqSstSQZQXX0',
  },
  LOCAL: {
    label: 'TEST',
    value: 'TEST',
    defaultCountryCode: "IN",
    domain: 'localhost',
    domainUrl: 'https://testing.que.zone',
    onboardingUrl: 'https://testing.provider.que.zone',

    BASE_URL: "http://localhost:8080",
    AWS_CONFIG: {
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-south-1:baa80567-968e-4acb-8a13-93037b755fee',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-south-1',
        identityPoolRegion: 'ap-south-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_tqaoV6YUZ',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3q88su8c01eov2r9hlpcupj2rt',
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
      },
      // hosted ui configuration
      oauth: {
        domain: 'que-zone.auth.ap-south-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:3000,https://que.zone',
        redirectSignOut: 'http://localhost:3000,https://que.zone',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    },
    FB_APP_ID: '243175483037775',
    FB_API_VERSION: 'v3.3',
    PROVIDER: {
      GOOGLE: 'google',
      FACEBOOK: 'facebook',
      QUEZONE: 'quezone',
    },
    QZ_COGNITO_IDENTITY_KEY: 'qz.auth.cognito.identity',
    AUTH_METHOD: 'auth2',
    GOOGLE_ID: '628080743577-4jujtdfhclapkg13pgbb2smflaah0vem.apps.googleusercontent.com',
    GOOGLE_SECRET: 'vRfkxHyr3Wwxjm8ZAYl7HcGJ',
    GOOGLE_GEO_API_KEY: 'AIzaSyATIXiytQDXMl_QyQWWD4t5L7JNgh6Eq_c',
    GOOGLE_CAPTCHA_SITE_KEY: '6Lc29bsUAAAAALLD3huMZVHQA2IL9UxJBDjTT6kH',
    GOOGLE_CAPTCHA_SECRET: '6Lc29bsUAAAAAL6j1N7wPWsH9EXcvqSstSQZQXX0',
  }
};

const region =
  /.*testcustweb.quezone.com.au/.test(window.location.hostname) ?
    "AU_TEST"
    :
    /.*custweb.quezone.com.au/.test(window.location.hostname) ?
      "AU"
      :
      /.*testing.que.zone/.test(window.location.hostname) ?
        "IN_TEST"
        :
        /.*que.zone/.test(window.location.hostname) ?
          "IN"
          :
          "IN"

export const Config = QuezoneRegionConfig[region]

