import styled from "@emotion/styled";
import { ExpandMore, Favorite, MoreVert, Share } from "@mui/icons-material"
import { Box, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, Collapse, IconButton, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { Config } from "../../config/regions";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import { defaultProductImageUrl } from "../../config/constants";
import { useCallback } from "react";
import { useMemo } from "react";

const ExpandMoreIcon = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    //marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ProductCard = ({ productData, isLoading }) => {

    const [expanded, setExpanded] = useState(false);
    const { orgRef } = useParams();
    const [expandEnabled, setExpandEnabled] = useState(true);
    const collapseContentRef = useRef(null)

    const handleHover = (expand) => {
        if (expandEnabled) {
            setExpanded(expand);
        }
    };

    useEffect(() => {
        if (collapseContentRef?.current) {
            if (collapseContentRef?.current?.clientHeight < 150) {
                setExpandEnabled(false);
            }
        }
    }, [collapseContentRef])

    const handleExpandClick = (e) => {
        e.preventDefault();
        setExpanded(!expanded);
    };
    const handleShareClick = async (e) => {
        e.preventDefault();
        if (navigator.share) {
            const shareDetails = { url: `${Config.domainUrl}/p/product/${orgRef}/${productData.id}`, text: productData.name };
            try {
                await navigator.share(shareDetails);
            } catch (error) {
                console.log(`Failed to Share`, error);
            }
        } else {
            console.log("Web share is currently not supported on this browser.");
        }
    };

    const productPrice = useMemo(() => {
        let price = '';
        if (productData?.services?.length) {
            let lowPriceService = productData.services.reduce((prev, curr) => prev?.price?.value && curr?.price?.value && prev.price?.value < curr.price?.value ? prev : curr);
            let highPriceService = productData.services.reduce((prev, curr) => prev?.price?.value && curr?.price?.value && prev.price?.value > curr.price?.value ? prev : curr);
            if (lowPriceService?.price?.value && highPriceService?.price?.value) {
                let lowValue = lowPriceService?.price?.value;
                let highValue = highPriceService?.price?.value;;
                price = lowValue !== highValue ?
                    lowValue + " - " + highValue + ' ' + (lowPriceService?.price?.currencySymbol ?? '')
                    :
                    lowValue + ' ' + (lowPriceService?.price?.currencySymbol ?? '')
            }
        }
        //console.log("getProductPrice>>", price);
        return price;
    }, [productData])

    return (
        <>
            {isLoading ?
                <SkeletonCard cardType="service" />
                :
                <Card
                    sx={{ width: '100%', maxHeight: '100%', borderRadius: 5 }}
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                >
                    <CardActionArea component={Link} to={`/p/product/${orgRef}/${productData?.id}`}
                        sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <CardMedia
                            component="img"
                            height="100"
                            image={productData?.productImage?.fileUrl ? productData?.productImage?.fileUrl : defaultProductImageUrl}
                            alt="Product Image"
                            sx={{ height: 150, width: 150, objectFit: 'cover', borderRadius: 5 }}
                        />
                        <Box sx={{ alignSelf: 'stretch', position: 'relative', flex: 1, overflow: 'auto' }}>
                            <Collapse in={expanded} timeout="auto" collapsedSize="110px">
                                <Box sx={{ p: 2 }} ref={collapseContentRef}>
                                    <Typography variant="body2" sx={{ fontSize: 12, fontWeight: "600" }}>
                                        {productData?.name}
                                    </Typography>
                                    {productData?.tags && productData?.tags?.split(',').length ?
                                        <Box sx={{ my: 2 }}>
                                            {
                                                productData?.tags?.split(',').map((tag, i) => i >= 0 && tag.trim() &&
                                                    <Box sx={{ mb: 0.5 }} key={i}>
                                                        <Chip color={"info"} variant="outlined" label={tag} size="small" sx={{ fontSize: 10 }} onClick={() => { }} />
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                        : null
                                    }
                                    <Typography variant="caption" paragraph sx={{ fontSize: 12, mb: 0 }}>
                                        {productData.description}
                                    </Typography>
                                </Box>
                            </Collapse>
                            <Box sx={{ position: 'relative', right: 0, bottom: 0, zIndex: 200, p: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'flex-end', width: '100%' }}>
                                {productPrice ?
                                    <Typography variant="overline" sx={{ mr: 'auto', px: 1, fontWeight: '600', whiteSpace: 'pre' }}>
                                        {productPrice}
                                    </Typography>
                                    : null
                                }
                                {expandEnabled ?
                                    <ExpandMoreIcon
                                        expand={expanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreIcon>
                                    : null
                                }
                                {navigator?.share ?
                                    <IconButton aria-label="share" onClick={handleShareClick} size="small">
                                        <Share />
                                    </IconButton>
                                    : null
                                }
                            </Box>
                        </Box>
                    </CardActionArea>
                </Card>
            }
        </>
    )
}
export default ProductCard